import React from "react";
import styled, { keyframes } from "styled-components";
import "./SkillBox2.scss";

const pulse = (percent) => keyframes`
    0% {
        width: 0px;
    }
    100% {
        width: ${percent}%;
    }`;

const SkillBoxStyled = styled.div`
  width: ${(props) => props.percent}%;
  background-color: #101010;
  animation: ${pulse((props) => props.percent)} 2s ease-out;
`;

const SkillBox = (props) => {
  const { data } = props;

  return (
    <div className="skill-box">
      <p className="skill-box-title font-weight-bold">
        {data.title}
        <span className="skill-box-percent align-right text-muted">
          {data.percent}%
        </span>
      </p>
      <div className="progress-bar">
        <SkillBoxStyled
          className="progress-bar-fill start"
          role="progressbar"
          percent={data.percent}
        />
      </div>
    </div>
  );
};

export default SkillBox;
