import React from "react";
import { dataSectionBrands } from "../../../data/sections";
import Image4 from "../../../images/ge02.svg";
import Image3 from "../../../images/lukoil02.png";
import Image1 from "../../../images/rosneft02.png";
import Image2 from "../../../images/tatneft02.png";
import SiteSection from "../../utils/SiteSection/SiteSection";
import "./Brands.scss";

const dataSection = {
  title: "Brands",
  subtitle: "Worked with",
  description: "",
};

const dataCards = [
  {
    title: "Rosneft",
    subtitle: "",
    description: "",
    image: Image1,
    alt: "",
  },
  {
    title: "Tatneft",
    subtitle: "",
    description: "",
    image: Image2,
    alt: "",
  },
  {
    title: "Lukoil",
    subtitle: "",
    description: "",
    image: Image3,
    alt: "",
  },
  {
    title: "GE",
    subtitle: "",
    description: "",
    image: Image4,
    alt: "",
  },
];

const Services = () => {
  const Card = (props) => {
    const { data } = props;

    return (
      <div className="logo_img">
        <img
          src={data.image}
          alt={data.alt}
          className="mx-auto img-fluid d-block"
        />
      </div>
    );
  };

  const SectionContent = () => {
    return (
      <div className="row">
        {dataCards.map((item, index) => {
          return (
            <div className="col-lg-3" key={index}>
              <Card data={item} key={index} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <SiteSection name="brands" data={dataSectionBrands} useOverlay>
      <SectionContent data={dataSection} />
    </SiteSection>
  );
};

export default Services;
