import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { dataMySkills } from "../../../data/aboutme";
import { dataSectionSkills } from "../../../data/sections";
import SiteSection from "../../utils/SiteSection/SiteSection";
import { Paragraph } from "../../utils/Utils";
import SkillBox from "./SkillBox2";
import "./Skills.scss";

const Skills = (props) => {
  //const { data } = props;
  const { t } = useTranslation("nsSkill");

  const SkillBoxes = () => {
    return (
      <div className="skills">
        {dataMySkills.map((item, index) => {
          return <SkillBox data={item} key={index} />;
        })}
      </div>
    );
  };

  const Tags = () => {
    const dataKeySkillsTags = i18next.t("nsSkill:key-skills", {
      returnObjects: true,
    });

    const Tag = (props) => {
      const { text } = props;
      return (
        <li>
          <span className="tag">{text}</span>
        </li>
      );
    };

    return (
      <div className="row">
        <div className="col-12" data-animate-effect="fadeInLeft">
          <ul className="tags">
            {dataKeySkillsTags.map((item, index) => {
              return <Tag key={index} text={item} />;
            })}
          </ul>
        </div>
      </div>
    );
  };

  const SectionContent = () => {
    const description = t("description");

    return (
      <div className="row">
        <div className="col-lg-6 mb-3" data-animate-effect="fadeInLeft">
          <Paragraph text={description} />
        </div>
        <div className="col-lg-6 mb-3" data-animate-effect="fadeInLeft">
          <SkillBoxes data={dataMySkills} />
        </div>
      </div>
    );
  };
  //
  return (
    <SiteSection name="skills" data={dataSectionSkills}>
      <SectionContent />
      <Tags />
    </SiteSection>
  );
};

export default Skills;
