import axios from "axios";
import draftToHtml from "draftjs-to-html";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { FaEdit, FaRegClock, FaRegUser, FaTrashAlt } from "react-icons/fa";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Context } from "../../context/Context";
import useFetchByURL from "../../hooks/useFetchByURL";
import SiteConfig from "../../Site.config";
import "../sections/Blog/Blog.scss";
import SingleArticleAside from "../sections/Blog/SingleArticleAside";

const CREATEDATE_FORMAT = SiteConfig.BLOG.PREVIEW_METADATA.CREATEDATE_FORMAT;

/*

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchSingleArticle = () => {
      try {
        axios
          .get("http://localhost:8001/api/articles/" + path, {
            cancelToken: source.token,
          })
          .then((response) => {
            //setSingleArticle(response.data);

            //
            // const editState = EditorState.createWithContent(
            //   convertFromRaw(JSON.parse(singleArticle.body))
            // );
            //const html = draftToHtml(singleArticle.body);
            //console.log(html);

            //setContentBody(html);

            //console.log("Fetch article data: ", singleArticle);
          });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Cancelled");
        } else {
          throw error;
        }
      }
    };
    fetchSingleArticle();
    return () => {
      source.cancel();
    };
  }, []);

*/
/**
 * Component for showing Blog Page of the site.
 *
 * @component
 */
const BlogPage = () => {
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname.split("/")[2];
  let { id } = useParams();

  const { user } = useContext(Context);

  // console.log(path, id);

  const serverURL = `http://localhost:8001/api/articles/${id}`;
  const [url, setUrl] = useState(serverURL);

  const [{ data, isLoading, isError }, doFetch] = useFetchByURL(url, {});
  const [singleArticle, setSingleArticle] = useState({});
  const [contentBody, setContentBody] = useState("");

  useEffect(() => {
    setUrl(`http://localhost:8001/api/articles/${id}`);
    doFetch(url);
  }, [url, id]);

  useEffect(() => {
    if (!isLoading) {
      setSingleArticle(data);
      try {
        setContentBody(draftToHtml(JSON.parse(data.body)));
      } catch (err) {
        setContentBody("");
      }
    }
  }, [data, isLoading]);

  const handleDeletePost = async () => {
    try {
      await axios.delete(`http://localhost:8001/api/articles/${path}`, {
        data: {
          username: user.username,
        },
      });

      // Go to blog page /blogs
      history.push("/blogs");
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditPost = async () => {
    // Go to blog page /blogs
    history.push(`/blogs/edit/${path}`);
  };

  // console.log(user);
  // console.log(singleArticle.username === user?.username);

  const SectionContent = () => {
    const Content = ({ data }) => {
      return (
        <div className="col-lg-8 px-md-5 py-5 float-left">
          {isError && <p>Error, data cant load...</p>}
          {isLoading ? (
            <p>Loading Data ...</p>
          ) : (
            <Fragment>
              <div className="post-img">
                <img className="img-fluid" src={data.image} alt="" />
              </div>
              <div className="post-detail">
                <div className="post-title">
                  <h2>{data.title}</h2>
                </div>
                <div className="post-meta">
                  <ul className="post-meta">
                    <li>
                      <Link
                        className="btn btn-link"
                        to={`/blogs/?user=${data.username}`}
                      >
                        <FaRegUser className="icon" />
                        <span>{data.username}</span>
                      </Link>
                    </li>
                    <li>
                      <FaRegClock className="icon" />
                      <span>
                        {new Date(data.createdAt).toLocaleString(
                          "ru",
                          CREATEDATE_FORMAT
                        )}
                      </span>
                    </li>
                  </ul>
                  {data.username === user?.username && (
                    <div className="post-edit">
                      <FaEdit className="icon" onClick={handleEditPost} />
                      <FaTrashAlt className="icon" onClick={handleDeletePost} />
                    </div>
                  )}
                </div>

                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{ __html: contentBody }}
                />

                <div className="post-footer">
                  <div className="post-tag">
                    {data.categories &&
                      data.categories.map((item, index) => {
                        return (
                          <Link
                            key={index}
                            className="btn btn-link"
                            to={`/blogs/?category=${item}`}
                          >
                            <span className="blank">{item}</span>
                          </Link>
                        );
                      })}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      );
    };

    return (
      <div className="container-fluid ">
        <div className="row">
          <Content data={singleArticle} />
          <SingleArticleAside />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <SectionContent />
    </Fragment>
  );
};

export default BlogPage;

/*

  //<pre>{JSON.stringify(user, null, 4)}</pre>
  //  <div className="g-5">





















  const Aside2 = (props) => {
    const { data } = props;
    return (
      <div className="col-lg-4 pt-5">
        <div className="card border-primary mb-4  sticky-top">
          <div className="card-body">
            <h5 className="card-title">Card</h5>
            <p className="card-text">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
            <a href="#" className="btn btn-outline-secondary">
              Outline
            </a>
          </div>
        </div>

        <div className="sidebar sticky-top">
          <p>
            <span id="time"></span> minute read
          </p>
          <GoBackButton />
          <ScrollIndicator />
        </div>
      </div>
    );
  };
  */
