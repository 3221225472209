import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { dataSocial } from "../../data/aboutme";
import { dataWebSite } from "../../data/website";
import i18n from "../../i18n";
import { SocialButtons } from "../utils/Buttons/SocialButtons";
import "./Aside.scss";
import MainMenu from "./MainMenu";

const Aside = (props) => {
  const LogoHero = () => {
    return (
      <div className="portfolio-aside-logo" id="portfolio-logo">
        <Link to="/">
          {dataWebSite.title}
          <span>{dataWebSite.subtitle}</span>
        </Link>
      </div>
    );
  };

  const HeroLogoTranslation = withTranslation()(LogoHero);

  const HeroFooter = () => {
    return (
      <div className="portfolio-aside-footer">
        <ChangeLanguageTranslation />
        <Social />
        <div className="copyright-group mt-3">
          <small>
            © 2021 HowTo1C by <Link to="#">HowTo1C.ru</Link>
          </small>
        </div>
      </div>
    );
  };

  const languages = [
    { title: "EN", lng: "en" },
    { title: "RU", lng: "ru" },
  ];

  const ChangeLanguage = () => {
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      console.log("Change lng: " + lng);
    };

    const language = i18n.language;
    return (
      <div className="language-group mb-3">
        <div className="btn btn-group">
          <button
            type="button"
            className="btn btn-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {language}
          </button>
          <div className="dropdown-menu">
            {languages.map((item, index) => {
              return (
                <button
                  key={index}
                  className="dropdown-item"
                  type="button"
                  onClick={() => changeLanguage(item.lng)}
                >
                  {item.title}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const ChangeLanguageTranslation = withTranslation()(ChangeLanguage);

  const Social = () => {
    return (
      <div className="social-group aside-social">
        <SocialButtons data={dataSocial} cn="black-icon" />
      </div>
    );
  };

  return (
    <aside
      className="portfolio-aside"
      id="portfolio-aside"
      onClick={props.onClick}
    >
      <HeroLogoTranslation />
      <MainMenu />
      <HeroFooter />
    </aside>
  );
};

export default Aside;

/*

<aside id="addo-aside">
            <!-- Logo -->
            <div id="addo-logo" wfd-id="120"> <a href="index.html">ADDO<span wfd-id="121">Adam Dornan</span></a> </div>
            <!-- Menu -->
            <nav id="addo-main-menu">
                <ul wfd-id="113">
                    <li wfd-id="119"><a href="index.html#home">Home</a></li>
                    <li wfd-id="118"><a href="index.html#about">About</a></li>
                    <li wfd-id="117"><a href="index.html#services">Services</a></li>
                    <li wfd-id="116"><a href="index.html#portfolio">Portfolio</a></li>
                    <li wfd-id="115"><a href="index.html#news">News</a></li>
                    <li wfd-id="114"><a href="index.html#contact">Contact</a></li>
                </ul>
            </nav>
            <!-- Sidebar Footer -->
            <div class="addo-footer" wfd-id="107">
                <ul wfd-id="108">
                    <li wfd-id="112"><a href="#"><i class="ti-facebook font-14px black-icon"></i></a></li>
                    <li wfd-id="111"><a href="#"><i class="ti-twitter-alt font-14px black-icon"></i></a></li>
                    <li wfd-id="110"><a href="#"><i class="ti-instagram font-14px black-icon"></i></a></li>
                    <li wfd-id="109"><a href="#"><i class="ti-linkedin font-14px black-icon"></i></a></li>
                </ul>
                <p><small>© 2021 ADDO by <a href="#">DuruThemes</a></small></p>
            </div>
        </aside>*/
