import React from "react";
import { Translation } from "react-i18next";

export const dataWebSite = {
  title: <Translation>{(t) => t("website.logo.title")}</Translation>,
  subtitle: <Translation>{(t) => t("website.logo.subtitle")}</Translation>,
};

export const dataWebSiteMeta = {
  title: "",
  subtitle: "",
  description: "",
  keywords:
    "Mobile development, WEB development, Software development, Application development",
  titleTemplate: "%s",
  robots: "",
  canonicalUrl: "http://www.lovkovav.pro",
};

export const dataWebSiteHomePageMeta = {
  title: "",
  subtitle: "",
};
