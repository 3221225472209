import i18n from "i18next";
//import Backend from 'i18next-http-backend';
import detector from "i18next-browser-languagedetector";
//import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import translationEN_nsAbout from "./locales/en/about.json";
import translationEN_nsExperience from "./locales/en/experience.json";
import translationEN_nsServices from "./locales/en/services.json";
import translationEN_nsSkill from "./locales/en/skill.json";
import translationEN from "./locales/en/translation.json";
import translationRU_nsAbout from "./locales/ru/about.json";
import translationRU_nsExperience from "./locales/ru/experience.json";
import translationRU_nsServices from "./locales/ru/services.json";
import translationRU_nsSkill from "./locales/ru/skill.json";
import translationRU from "./locales/ru/translation.json";

// The translations
const resources = {
  en: {
    translation: translationEN,
    nsAbout: translationEN_nsAbout,
    nsSkill: translationEN_nsSkill,
    nsExperience: translationEN_nsExperience,
    nsServices: translationEN_nsServices,
  },
  ru: {
    translation: translationRU,
    nsAbout: translationRU_nsAbout,
    nsSkill: translationRU_nsSkill,
    nsExperience: translationRU_nsExperience,
    nsServices: translationRU_nsServices,
  },
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(detector)
  //.use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    resources,

    //backend: {
    //  loadPath: '/static/locales/{{lng}}/{{ns}}.json'
    //},

    // use en if detected lng is not available
    fallbackLng: "en",
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    lng: "en",
    // we do not use keys in form messages.welcome
    //keySeparator: true,
    // we use content as keys
    keySeparator: ".",

    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ",",
    },

    // detection options
    detection: {
      checkWhitelist: true, // options for language detection
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },

    // react-i18next options
    //react: {
    //  wait: true,
    //},
  });

export default i18n;
