import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import Image from "../../../images/cookies.svg";
import { Paragraph } from "../../utils/Utils";
import PoliciesModal from "../PoliciesModal/PoliciesModal";
// import "./CookiePolicy.scss";

/*
Этот сайт использует куки для предоставления лучшего пользовательского опыта. Продолжая использовать сайт, вы даете свое согласие на сбор и использование таких данных. Для уточнения деталей, изучите нашу
 */

const CookiePolicy = () => {
  const [showPolicy, setShowPolicy] = useState(false);

  const { t } = useTranslation();
  const title = t("website.cookiepolicy.title");
  const policyLink = t("website.cookiepolicy.link");

  //let cookieName = "lovkovavCookie";
  const cookieName = process.env.REACT_APP_COOKIE_NAME;
  const DEBUG = process.env.REACT_APP_DEBUG;
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  const handleShowPolicy = (e) => {
    setShowPolicy(true);
  };

  useEffect(() => {
    //Cookies.remove(cookieName);
    //console.log("cookie: ", Cookies.get(cookieName));
    //Cookies.set(cookieName, 500);
    //console.log("cookie: ", Cookies.get(cookieName));
  }, []);

  return (
    <div className="cookie-bar cookie-bar-glass">
      <CookieConsent
        debug={isDev}
        location="bottom"
        buttonText="Agree!"
        cookieName={cookieName}
        ariaAcceptLabel="cookieconsent"
        disableStyles={true}
        buttonClasses="btn btn-secondary btn-cookie"
        buttonWrapperClasses="cookie-bar-button"
        containerClasses="container cookie-bar-container"
        contentClasses="cookie-bar-content d-flex"
      >
        <img alt="cookies" src={Image}></img>
        <div className="cookie-bar-description">
          <Paragraph text={title} span />
          <button
            className="btn btn-link link"
            aria-label="Conditions"
            onClick={handleShowPolicy}
          >
            <span>{policyLink}</span>
          </button>
        </div>
        {"."}
      </CookieConsent>
      <PoliciesModal onClose={() => setShowPolicy(false)} show={showPolicy} />
    </div>
  );
};

export default CookiePolicy;
