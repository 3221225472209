import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import CookiePolicy from "./components/sections/Cookie/CookiePolicy";
import { AppMetaTranslation } from "./components/SEO/AppTitle";
import SiteRouter from "./components/utils/SiteRouter/SiteRouter";

const App = (props) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <AppMetaTranslation />
        <SiteRouter />
        <CookiePolicy />
      </div>
    </HelmetProvider>
  );
};

export default App;

/*  */
