import axios from "axios";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

/**
 * Component for showing Page "NotFount" of the site.
 *
 * @component
 */
const Register = () => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isError, setIsError] = useState(false);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newUser = {
      userName,
      userEmail,
      userPassword,
    };
    console.log(newUser);

    setIsError(false);

    try {
      const res = await axios.post(
        "http://localhost:8001/api/auth/register",
        newUser,
        { headers: { "Content-Type": "application/json" } }
      );

      console.log(res);

      // res.data && window.location.replace("/login");
      res.data && history.push("/login");
    } catch (err) {
      setIsError(true);
      console.error(err);
    }
  };

  //    <main className="page-notfount">

  return (
    <main className="portfolio-main" id="portfolio-main">
      <div className="register">
        <span className="register-title">Register</span>
        <form className="register-form" onSubmit={handleSubmit}>
          <label>Username</label>
          <input
            type="text"
            className="register-input"
            placeholder="Your name"
            onChange={(e) => setUserName(e.target.value)}
          ></input>
          <label>Email</label>
          <input
            type="text"
            className="register-input"
            placeholder="Your email"
            onChange={(e) => setUserEmail(e.target.value)}
          ></input>
          <label>Password</label>
          <input
            type="text"
            className="register-input"
            placeholder="Your passowrd"
            onChange={(e) => setUserPassword(e.target.value)}
          ></input>
          <button className="btn btn-primary" type="submit">
            Register
          </button>
        </form>
        <button className="btn btn-link">
          <Link className="link" to="/login">
            Login
          </Link>
        </button>
        {isError && <span>Something went wrong!</span>}
      </div>
    </main>
  );
};

export default Register;
