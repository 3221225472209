import React from "react";
import { useTranslation } from "react-i18next";
import { dataSectionLetsContact } from "../../../data/sections";
import { Button as ButtonLetsContact } from "../../utils/Buttons/Button";
import SiteSection from "../../utils/SiteSection/SiteSection";
import "./LetsContact.scss";

const LetsContact = (props) => {
  const { path } = props;
  const { t } = useTranslation();
  const title = t("sections.letscontact.title");
  const subtitle = t("sections.letscontact.subtitle");

  const SectionContent = () => {
    return (
      <div className="row">
        <div className="col-lg-12 text-center">
          {title && <h2 className="font-weight-normal text-white">{title}</h2>}
          {subtitle && (
            <h3 className="font-weight-normal text-white">{subtitle}</h3>
          )}
          <div className="mt-4 pt-3">
            <ButtonLetsContact
              cn="btn btn-primary btn-white btn-glass"
              text={dataSectionLetsContact.button}
              path={path}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <SiteSection name="letscontact" data={dataSectionLetsContact} useOverlay>
      <SectionContent data={dataSectionLetsContact} />
    </SiteSection>
  );
};

export default LetsContact;
