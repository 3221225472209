import React from "react";
import { Link } from "react-router-dom";
import { cnj } from "../Utils";

export const DownloadCV = (props) => {
  const { text, path, cn, handleClick } = props;
  const linkInsteadOfButton = true;

  return (
    <span>
      {linkInsteadOfButton ? (
        <Link
          className={cnj("btn", cn && `${cn}`)}
          to={path}
          target="_blank"
          download
        >
          {text}
        </Link>
      ) : (
        <button
          className={cnj("btn", cn && `${cn}`)}
          type="button"
          aria-label={text}
          onClick={handleClick}
        >
          {text}
        </button>
      )}
    </span>
  );
};
