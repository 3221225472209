import ReactGA from "react-ga";
import AppConfig from "../../App.config";

const GA_TRACKING_ID = AppConfig.GOOGLE.GA_TRACKING_CODE;
/**
 *   useEffect(() => {
    ReactGA.initialize(, { debug: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
 */
const initGA = () => {
  // Enable debug mode on the local development environment
  const isDev = false; // !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  console.debug("GA init", isDev);

  ReactGA.initialize(GA_TRACKING_ID, { debug: isDev });
};

const logPageView = (url) => {
  console.debug(`Logging pageview for ${url}`);
  ReactGA.set({ page: url });
  ReactGA.pageview(url);
};

const logEvent = (category = "", action = "") => {
  if (category && action) {
    ReactGA.event({ category, action });
  }
};

const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};

export default {
  initGA,
  logPageView,
  logEvent,
  logException,
};
