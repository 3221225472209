import React from "react";
import { withTranslation } from "react-i18next";
import SectionTitle from "../../utils/SiteTitle/SiteTitle";
import { cnj } from "../Utils";

const SiteSection = (props) => {
  const { name, className, data, useOverlay } = props;

  return (
    <section
      className={cnj(
        "site-section section",
        `section-${name}`,
        className && `${className}`
      )}
      id={`section-${name}`}
      name={`section-${name}`}
    >
      {useOverlay && <div className="bg-overlay" />}
      <div className="container-fluid">
        <SectionTitle data={data} />
        {props.children}
      </div>
    </section>
  );
};

//export default SiteSection;
export default withTranslation()(SiteSection);
