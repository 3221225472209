import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GoBackButton } from "../../utils/Buttons/GoBackButton";
import ScrollIndicator from "../../utils/ScrollIndicator";
import "./Blog.scss";
import SingleArticleAsideCategories from "./SingleArticleAsideCategories";

/**
 * Component for showing Blog Page of the site.
 *
 * @component
 */

const SingleArticleAside = () => {

  return (
    <div className="col-lg-4 pt-5">
      <div className="card border-primary mb-4  sticky-top">
        <div className="card-body">
          <h5 className="card-title">Card</h5>
          <p className="card-text">
            With supporting text below as a natural lead-in to additional
            content.
          </p>
          <a href="#" className="btn btn-outline-secondary">
            Outline
          </a>
        </div>
      </div>
      <div className="sidebar sticky-top">
        <p>
          <span id="time"></span> minute read
        </p>
        <GoBackButton />
        <ScrollIndicator />
      </div>
      <SingleArticleAsideCategories />
    </div>
  );
};

export default SingleArticleAside;
