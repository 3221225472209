import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";

/**
 * Component for showing Page "NotFount" of the site.
 *
 * @component
 */
const LoginPage = () => {
  const {  dispatch, isFetching } = useContext(Context);
  const [isError, setIsError] = useState(false);

  const userRef = useRef();
  const passwordRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch({ type: "LOGIN_START" });

    try {
      const res = await axios.post("http://localhost:8001/api/auth/login", {
        username: userRef.current.value,
        password: passwordRef.current.value,
      });

      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      //console.log(user);
    } catch (err) {
      console.error(err);
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };

 // console.log(user);
 // console.log(isFetching);

  return (
    <main className="portfolio-main" id="portfolio-main">
      <div className="login">
        <span className="login-title">Register</span>
        <form className="login-form" onSubmit={handleSubmit}>
          <label>Username</label>
          <input
            type="text"
            className="register-input"
            placeholder="Your username"
            ref={userRef}
          ></input>

          <label>Password</label>
          <input
            type="text"
            className="register-input"
            placeholder="Your passowrd"
            ref={passwordRef}
          ></input>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isFetching}
          >
            Login
          </button>
        </form>
        <button className="btn btn-link">
          <Link className="link" to="/register">
            Register
          </Link>
        </button>
        {isError && <span>Something went wrong!</span>}
      </div>
    </main>
  );
};

export default LoginPage;
