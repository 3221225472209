import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { dataSectionContact } from "../../../data/sections";
import SiteSection from "../../utils/SiteSection/SiteSection";
import { log, Paragraph } from "../../utils/Utils";
import ContactDetail from "../AboutMe/ContactDetal";
import "./Contact.scss";
import ContactForm from "./ContactForm";

const Contact = () => {
  const [, setShowPolicy] = useState(false);

  const [, setStatus] = useState("Submit");
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(null);

  const serviceId = "service_lxc4arj";
  const templateId = "template_5fabye1";

  const sendFeedback = (serviceId, templateId, variables) => {
    window.emailjs
      .send(serviceId, templateId, variables)
      .then((res) => {
        log("Email successfully sent!");
        setSubmit(true);
        setError(false);
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => {
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
        setSubmit(false);
        setError(true);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus("Sending...");

    const { fullname, email, message } = e.target.elements;

    let details = {
      fullname: fullname.value,
      email: email.value,
      message: message.value,
    };

    console.log(details);

    sendFeedback(serviceId, templateId, details);

    setStatus("Submit");
  };

  const handleShowPolicy = (e) => {
    setShowPolicy(true);
  };

  const successMessage = "Thank You. Your message Submitted!";
  const errorMessage = "Your message did not Submitted!";

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = "";
    let emailValid = value;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors = emailValid ? "" : " is invalid";
        console.log(fieldValidationErrors);
        break;
      default:
        break;
    }
  };

  const config = { title: "" };

  const SectionContentContactForm = () => {
    return (
      <div className="contact contact-form">
        <ContactForm
          onSubmit={handleSubmit}
          onShowPolicy={handleShowPolicy}
          validateField={validateField}
          config={config}
        />
        <div>
          {submit && (
            <div className="message-success">
              <p>{successMessage}</p>
            </div>
          )}
          {error && (
            <div className="message-error">
              <p>{errorMessage}</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const SectionContent = () => {
    const { t } = useTranslation("nsAbout");
    const details = t("details", { returnObjects: true });
    const description = t("contact.description");

    return (
      <div className="row">
        <div className="col-lg-4 mb-3" data-animate-effect="fadeInLeft">
          <Paragraph text={description} />
          <br />
          {details[1].col.map((itemDetail, indexDetail) => {
            return <ContactDetail key={indexDetail} data={itemDetail} />;
          })}
        </div>

        <div className="col-lg-8 mb-3" data-animate-effect="fadeInLeft">
          <SectionContentContactForm />
        </div>
      </div>
    );
  };

  return (
    <SiteSection name="contact" data={dataSectionContact}>
      <SectionContent />
    </SiteSection>
  );
};

export default Contact;

/*

          <p>
            <b>ADDO COMPANY INC.</b>
            <br />
            Vivamus nec magna rhoncus felis faucibus printy.
            <br />
            Faucibus printy nec magna rhoncus printy.

*/
