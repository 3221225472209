import React from "react";
import { withTranslation } from "react-i18next";

const SectionTitle = (props) => {
  const { data } = props;
  const { title, subtitle } = data;
  return (
    <div className="row">
      <div className="col-md-12">
        {subtitle && <span className="section-title-meta">{subtitle}</span>}
        {title && (
          <h2
            className="section-title animate-box fadeInLeft animated"
            data-animate-effect="fadeInLeft"
          >
            {title}
          </h2>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SectionTitle);
