import React, { useContext, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { NavLink as PageLink, useHistory, useLocation } from "react-router-dom";
import { Link as NavLink, scroller } from "react-scroll";
import { Context } from "../../context/Context";
import "./Aside.scss";

const MainMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, dispatch } = useContext(Context);

  let location = useLocation();

  const [pathname, setPathname] = useState("");
  const [elementName, setElementName] = useState("");

  const handleLogout = () => {
    if (user) {
      dispatch({ type: "LOGOUT" });
      history.push("/");
    } else {
      history.push("/login");
    }
  };

  React.useEffect(() => {
    if (location.pathname !== pathname) setPathname(location.pathname);
    if (location.pathname === "/" && elementName) {
      scroller.scrollTo(elementName, { smooth: true });
      setElementName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleSetActivePage = (e) => {
    e.preventDefault();
    const name = e.target.name;
    setElementName(name);

    history.push("/");
  };

  return (
    <nav id="addo-main-menu">
      <ul>
        <li>
          {pathname === "/" ? (
            <NavLink to="section-main-hero" activeClass="active">
              {t("main-menu.home")}
            </NavLink>
          ) : (
            <PageLink
              to="/"
              activeClassName="active"
              onClick={handleSetActivePage}
              name="section-main-hero"
            >
              {t("main-menu.home")}
            </PageLink>
          )}
        </li>
        <li>
          {pathname === "/" ? (
            <NavLink to="section-about" activeClass="active" spy smooth>
              {t("main-menu.about")}
            </NavLink>
          ) : (
            <PageLink
              to="/"
              activeClassName="active"
              onClick={handleSetActivePage}
              name="section-about"
            >
              {t("main-menu.about")}
            </PageLink>
          )}
        </li>
        <li>
          {pathname === "/" ? (
            <NavLink to="section-skills" activeClass="active" spy smooth>
              {t("main-menu.skills")}
            </NavLink>
          ) : (
            <PageLink
              to="/"
              activeClassName="active"
              onClick={handleSetActivePage}
              name="section-skills"
            >
              {t("main-menu.skills")}
            </PageLink>
          )}
        </li>
        <li>
          {pathname === "/" ? (
            <NavLink to="section-services" activeClass="active" spy smooth>
              {t("main-menu.services")}
            </NavLink>
          ) : (
            <PageLink
              to="/"
              activeClassName="active"
              onClick={handleSetActivePage}
              name="section-services"
            >
              {t("main-menu.services")}
            </PageLink>
          )}
        </li>
        <li>
          {pathname === "/" ? (
            <NavLink to="section-brands" activeClass="active" spy smooth>
              {t("main-menu.portfolio")}
            </NavLink>
          ) : (
            <PageLink
              to="/"
              activeClassName="active"
              onClick={handleSetActivePage}
              name="section-brands"
            >
              {t("main-menu.portfolio")}
            </PageLink>
          )}
        </li>
        <li>
          <PageLink to="/blogs" activeClassName="active">
            Blogs
          </PageLink>
        </li>
        <li>
          {pathname === "/" ? (
            <NavLink to="section-contact" activeClass="active" spy smooth>
              {t("main-menu.contact")}
            </NavLink>
          ) : (
            <PageLink
              to="/"
              activeClassName="active"
              onClick={handleSetActivePage}
              name="section-contact"
            >
              {t("main-menu.contact")}
            </PageLink>
          )}
        </li>
        <li>
          {user ? (
            <NavLink to="/" onClick={handleLogout}>LOGOUT</NavLink>
          ) : (
            <NavLink to="/" onClick={handleLogout}>LOGIN</NavLink>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default withTranslation()(MainMenu);
