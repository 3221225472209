import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import "./Blog.scss";

/**
 * Component for showing SingleArticleAsideCategories of the site.
 *
 * @component
 */
const SingleArticleAsideCategories = () => {
  const [cat, setCat] = useState(null);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchCategories = () => {
      try {
        axios
          .get("http://localhost:8001/api/categories", {
            cancelToken: source.token,
          })
          .then((response) => {
            setCat(response.data);
            console.log("Fetch caterories: ", cat);
          });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Cancelled");
        } else {
          throw error;
        }
      }
    };
    fetchCategories();
    return () => {
      source.cancel();
    };
  }, []);

  const axiosConfig = { method: "GET" };
  const { isLoading, isError, response } = useAxios(
    `http://localhost:8001/api/categories`,
    axiosConfig
  );

  const CategoryLink = (props) => {
    const { data } = props;
    return (
      <Link className="btn btn-link" to={`/blogs/?category=${data.name}`}>
        {data.name} <span>(6)</span>
      </Link>
    );
  };

  return (
    <div className="sidebar-box">
      <h3 className="sidebar-heading">Categories</h3>
      <ul className="categories">
        {isLoading ? (
          <p>Loading Data ...</p>
        ) : (
          <Fragment>
            {response?.data &&
              response.data.map((item, index) => {
                return (
                  <li key={index}>
                    <CategoryLink data={item} />
                  </li>
                );
              })}
          </Fragment>
        )}
      </ul>
    </div>
  );
};

export default SingleArticleAsideCategories;

/*


{response?.data &&
              response.data.length > 0 &&
              response.data.map((item, index) => {
                return (
                  <li>
                    <Link
                      className="btn btn-link"
                      to={`/blogs/?category=${item.name}`}
                      key={index}
                    >
                      {item.name} <span>(6)</span>
                    </Link>
                  </li>
                );
              })}
              */
