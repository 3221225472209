import React, { lazy, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import useGoogleAnalytics from "../../../hooks/useGoogleAnalytics";
import { useWindowSize } from "../../../hooks/useWindowSize";
import BlogPage from "../../pages/BlogPage";
//import BlogsPage from "../../pages/BlogsPage";
import HomePage from "../../pages/HomePage";
import LoginPage from "../../pages/LoginPage";
import MainLayoutPage from "../../pages/MainLayoutPage";
import RegisterPage from "../../pages/RegisterPage";
//import NotFoundPage from "../../pages/NotFoundPage";
//import Countdown from "../../sections/Countdown/Countdown";
import { checkCountdownTime } from "../../sections/Countdown/CountdownTimer";

const AboutPageLazy = lazy(() =>
  import(/* webpackChunkName: 'AboutPage' */ "../../pages/AboutPage")
);

const BlogsPageLazy = lazy(() =>
  import(/* webpackChunkName: 'BlogsPage' */ "../../pages/BlogsPage")
);

const NotFoundPageLazy = lazy(() =>
  import(/* webpackChunkName: 'NotFoundPage' */ "../../pages/NotFoundPage")
);

const CountdownLazy = lazy(() =>
  import(
    /* webpackChunkName: 'Countdown' */ "../../sections/Countdown/Countdown"
  )
);

const WriteArticlePageLazy = lazy(() =>
  import(
    /* webpackChunkName: 'WriteArticlePageLazy' */ "../../pages/WriteArticlePage"
  )
);

const SiteRouter = () => {
  // const isShutdown = process.env.REACT_APP_IS_SHUTDOWN;
  const [isShown, setIsShown] = useState(false);
  const isShutdown = process.env.REACT_APP_IS_SHUTDOWN && checkCountdownTime();

  const windowSize = useWindowSize();

  const showSidebar = () => {
    if (windowSize.width <= 768) setIsShown(!isShown);
    console.log(isShown);
  };

  // Use GA analitics
  useGoogleAnalytics();

  if (isShutdown) {
    return (
      <Switch>
        <Route exact path="/" component={CountdownLazy} />
        <Route component={NotFoundPageLazy} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <MainLayoutPage isShown={isShown} onClick={showSidebar}>
          <Route path="/" exact component={HomePage} />
          <Route path="/login" exect component={LoginPage} />
          <Route path="/register" exect component={RegisterPage} />
          <Route path="/about" exact component={AboutPageLazy} />
          <Route path="/blogs" exect component={BlogsPageLazy} />
        </MainLayoutPage>
        <Route path="/404" component={NotFoundPageLazy} />
        <Redirect to="/404" />
      </Switch>
    );
  }
};

//
//<Route path="/blogs/edit" strict component={WriteArticlePageLazy} />
//          <Route path="/post/:id" component={BlogPage} />
//<Route path="/blogs/edit/:id" component={WriteArticlePageLazy} />
export default withRouter(SiteRouter);
/*
<Redirect exact from="/" to="/home" />
*/
