import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { dataWebSiteMeta } from "../../data/website";
import { log } from "../utils/Utils";

export const AppMeta = (props) => {
  const { t, i18n } = props;

  const getCurrentLanguage = () => {
    const language = i18n.language;
    log("Current lang: " + language);
    return language;
  };

  const meta = {
    title: dataWebSiteMeta?.title || t("website.title"),
    subtitle: dataWebSiteMeta?.subtitle || t("website.subtitle"),
    description: dataWebSiteMeta?.description || t("website.description"),
    keywords: dataWebSiteMeta?.keywords,
    titleTemplate: dataWebSiteMeta?.titleTemplate,
  };

  // console.log(meta.title, meta);

  return (
    <Helmet titleTemplate={meta.titleTemplate}>
      <html lang={getCurrentLanguage()} />
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="apple-mobile-web-app-title" content="2" />
      <meta name="keywords" content={meta.keywords} />
      <meta data-react-helmet="true" name="ROBOTS" content="INDEX" />
    </Helmet>
  );
};

export const AppMetaTranslation = withTranslation()(AppMeta);

export const PageMeta = (props) => {
  //const { t, i18n } = props;

  const meta = {
    title: "",
    subtitle: "",
    description: "",
  };

  // console.log(meta.title, meta);

  return <Helmet title={meta.title} description={meta.description}></Helmet>;
};

export const PageMetaTranslation = withTranslation()(PageMeta);

/*
   title: title || defaultData?.title,
    description: description || defaultData?.description,
    keywords: keywords || defaultData?.keywords,
    robots: robots || defaultData?.robots,
    canonicalUrl: canonicalUrl || defaultData?.canonicalUrl,
      script=
      {[
        {
          type: "application/ld+json",
          innerHTML: `{
              "@context": "http://schema.org",
              "@type": "NewsArticle"
            }`,
        },
      ]}

      <base target="_blank" href="http://mysite.com/" />
      <meta property="og:type" content="article" />
      <meta name="theme-color" content="#008f68" />
      <meta data-react-helmet="true" name="ROBOTS" content="INDEX,FOLLOW" />
      <link rel="apple-touch-icon" href="/logo192.png" />
      <link rel="manifest" href="/manifest.json" />*/
