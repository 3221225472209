import React from "react";
import { Link } from "react-router-dom";
import Aside from "../layout/Aside";
//import CookiePolicy from "../sections/Cookie/CookiePolicy";

/**
 * Component for showing Main Layout Page of the site.
 *
 * @component
 */
const MainLayoutPage = (props) => {
  return (
    <div
      className={props.isShown ? "page-portfolio offcanvas" : "page-portfolio"}
      data-spy="scroll"
      data-target="portfolio-aside"
      data-offset="50"
      cz-shortcut-listen="true"
    >
      <Link
        to="/"
        className={
          props.isShown
            ? "js-addo-nav-toggle addo-nav-toggle active"
            : "js-addo-nav-toggle addo-nav-toggle"
        }
        onClick={props.onClick}
      >
        <i></i>
      </Link>
      <Aside onClick={props.onClick} />
      {props.children}
    </div>
  );
};

export default MainLayoutPage;

/*
<CookiePolicy />
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-FY8F40RH9J"
    ></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-FY8F40RH9J");
    </script>

useEffect(() => {
    ReactGA.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE, { debug: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

 */
