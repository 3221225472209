import React from "react";

const DEBUG = process.env.REACT_APP_DEBUG;

export const cnj = (...args) => args.filter(Boolean).join(" ");

export const SectionTitle = (props) => {
  const { data } = props;
  const { title, subtitle } = data;
  return (
    <div className="row">
      <div className="col-md-12">
        <span className="section-title-meta">{subtitle}</span>
        <h2
          className="section-title animate-box fadeInLeft animated"
          data-animate-effect="fadeInLeft"
        >
          {title}
        </h2>
      </div>
    </div>
  );
};

export const SectionTitle1 = (props) => {
  const { title, subtitle, description, isShown, isBackground } = props;

  if (!isShown) {
    return null;
  }

  return (
    <div className={isBackground ? "section-title-bg" : "section-title"}>
      <h2>{title}</h2>
      {subtitle && <h4>{subtitle}</h4>}
      {description && <p>{description}</p>}
    </div>
  );
};

export const SectionIconTitle = (props) => {
  const isShown = props.isShown;
  const isBackground = props.isBackground;
  const sectionTitle = props.sectionTitle;
  const sectionDescription = props.sectionDescription;

  if (isShown) {
    return (
      <div className={isBackground ? "section-title-bg" : "section-title"}>
        {props.icon && (
          <div className="section-title-icon">
            <img src={props.icon} alt="Technology icon | LovkovAV.PRO"></img>
          </div>
        )}
        <h2>{sectionTitle}</h2>
        {sectionDescription && <p>{sectionDescription}</p>}
      </div>
    );
  }
  return "";
};

export const Paragraph = (props) => {
  const { text, span } = props;
  const newText = text
    .split("\n")
    .map((str, index) =>
      span ? <span key={index}>{str}</span> : <p key={index}>{str}</p>
    );

  return newText;
};

export const checkForError = response => {
  if (!response.ok) throw Error(response.statusText);
  return response.json();
};

export const log = (props) => {
  //const { message } = props;
  if (DEBUG) {
    //    if (debug) {
    //    console.debug(message);
    // } else {
    //console.log(message);
    // }
  }
};
