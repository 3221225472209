import React from "react";

const ContactDetail = (props) => {
  const { data } = props;
  return (
    <p>
      <b>{data.title}:</b>{" "}
      {data.path ? (
        <a href={data.path} rel="noopener noreferrer" target="_blank">
          {data.description}
        </a>
      ) : (
        data.description
      )}
    </p>
  );
};

export default ContactDetail;
