import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Typewriter from "typewriter-effect";
import { dataSectionMainHero } from "../../../data/sections";
import Image1 from "../../../images/poster_blur-min.jpg";
import VideoMP4 from "../../../video/video2.mp4";
import "./MainHero.scss";

const MainHero = () => {
  const TypewriterTitle = () => {
    const { t } = useTranslation();
    const text1 = t("sections.mainhero.details.0");
    const text2 = t("sections.mainhero.details.1");
    const text3 = t("sections.mainhero.details.2");
    const text4 = t("sections.mainhero.details.3");

    return (
      <h2 className="main-hero-typewriter">
        {text1 && text1}
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
            wrapperClassName: "typewriter-wrapper",
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(text2)
              .pauseFor(2000)
              .deleteAll()
              .typeString(text3)
              .pauseFor(2000)
              .deleteAll()
              .typeString(text4)
              .pauseFor(2000)
              .deleteAll()
              .start();
          }}
        />
      </h2>
    );
  };

  const MainHeroTitle = () => {
    return (
      <div className="main-hero-content">
        <h1 className="main-hero-title">{dataSectionMainHero.title}</h1>
        <h2 className="main-hero-subtitle">{dataSectionMainHero.subtitle}</h2>
        <TypewriterTitle />
      </div>
    );
  };

  const MainHeroTitleTranslation = withTranslation()(MainHeroTitle);

  const SectionContent = () => {
    return (
      <div className="main-hero">
        <div className="effects bg-overlay"></div>
        <video
          autoPlay
          loop
          muted
          playsInline
          poster={Image1}
          src={VideoMP4}
          webkits-playsinline=""
        >
          <source src={VideoMP4} type="video/mp4" />
          Your browser doesn't support HTML5 video tag.
        </video>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <MainHeroTitleTranslation />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section
      className="site-section section section-main-hero"
      id="section-main-hero"
    >
      <SectionContent />
    </section>
  );
};

export default MainHero;

/*

          <source src={Video1} type="video/mp4" />
          <source src={Video2} type="video/webm">
            Sorry, your browser doesn't support embedded videos, but don't
            worry, you can <a href="/static/video/video_.mp4">download it</a>{" "}
            and watch it with your favorite video player!
          </source>
*/
/*

  <div class="hero-block-large__wrapper" wfd-id="232">
  <video autoplay="" loop="" muted="" playsinline="" poster="/static/video/poster_blur-min.jpg"
  src="/static/video/video_.mp4"
  webkits-playsinline="">
  <source src="/static/video/video_.mp4" type="video/mp4">
  <source src="/static/video/video_.webm" type="video/webm">Sorry, your browser doesn't support embedded videos, but don't worry, you can <a href="/static/video/video_.mp4">download it</a> and watch it with your favorite video player!
  </video>
  <div class="block-content" wfd-id="233">
  <h1 class="block-title">You dream it, we build it</h1><div class="block-description" wfd-id="234">iTechArt is a one-stop source for software development. We provide VC-backed startups and fast-growing tech companies with dedicated engineering teams, delivering scalable products that users love.</div><a class="block-action-button" href="https://www.itechart.com/company/contacts/#address">Get started</a></div></div>

  */

//
