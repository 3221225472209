import axios from "axios";
import { useEffect, useState } from "react";

const useFetchByURL = (initialUrl, initialData) => {
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [status, setStatus] = useState("idle");

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    if (!url) return;

    const fetchData = async () => {
      try {
        setIsError(false);
        setIsLoading(true);
        setStatus("fetching");

        const response = await axios.get(url, {
          cancelToken: source.token,
        });

        const json = await response.data;

        setData(json);
        setStatus("fetched");
      } catch (error) {
        setStatus("error");
        setIsError(true);

        if (axios.isCancel(error)) {
          console.log("Cancelled", error);
        } else {
          throw error;
        }
      }

      setIsLoading(false);
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, [url]);

  return [{ data, isLoading, isError, status }, setUrl];
};

export default useFetchByURL;
