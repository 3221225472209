import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { dataSocial } from "../../data/aboutme";
import { dataWebSite } from "../../data/website";
import PoliciesModal from "../sections/PoliciesModal/PoliciesModal";
import { SocialButtons } from "../utils/Buttons/SocialButtons";
import "./Footer.scss";

const Footer = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const footerTitle = t("sections.footer.title");
  const footerSubtitle = t("sections.footer.subtitle");

  const Social = () => {
    return (
      <div className="social-group footer-social hoverontouch">
        <SocialButtons data={dataSocial} cn="hoverontouch" />
      </div>
    );
  };

  return (
    <footer className="site-footer section">
      <div className="footer-top">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-lg-4 mb-3">
              {footerTitle && (
                <h2 className="footer-top-title">{footerTitle}</h2>
              )}
              {footerSubtitle && (
                <h4 className="footer-top-subtitle">{footerSubtitle}</h4>
              )}
            </div>

            <div className="col-12 col-lg-4 mb-3">
              <h2 className="footer-top-logo text-center">
                {dataWebSite.title}
                <br />
                <span>{dataWebSite.subtitle}</span>
              </h2>
            </div>
            <div className="col-12 col-lg-4 mb-3">
              <Social />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p className="footer-copyright-lead">
          Copyright © 2021 <Link to="#">HowTo1C.ru</Link> | All rights reserved
          <button
            className="btn btn-link footer-copyright-link"
            aria-label="Policies"
            onClick={() => {
              setShow(true);
            }}
          >
            <span>Policies</span>
          </button>
        </p>
      </div>
      <PoliciesModal onClose={() => setShow(false)} show={show} />
    </footer>
  );
};

export default Footer;

/*


                <Link className={item.cn} to={item.path} target="_blank">
                  {item.icon}
                </Link>

        <ul class="list-inline social-network">
          {dataSocial.map((item, index) => {
            return (
              <li className="list-inline-item" key={index}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={item.path}
                  className={item.cn}
                >
                  {item.icon}
                </a>
              </li>
            );
          })}
        </ul>
*/
