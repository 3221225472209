import { Link, useHistory } from "react-router-dom";
import { cnj } from "../Utils";

export const GoBackButton = (props) => {
  const { text, path, cn, handleClick } = props;
  const history = useHistory();
  const linkInsteadOfButton = false;

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      {linkInsteadOfButton ? (
        <Link className={cnj("btn", cn && `${cn}`)} to={path}>
          {text}
        </Link>
      ) : (
        <button
          className={cnj("btn", cn && `${cn}`)}
          type="button"
          onClick={() => {
            handleClick ? handleClick() : handleGoBack();
          }}
          aria-label={text}
        >
          {text ? { text } : "Go Back"}
        </button>
      )}
    </div>
  );
};

/* */
