import React from "react";
import { cnj } from "../Utils";

export const ListItems = (props) => {
  const { data, clUl, cnLi, icon } = props;

  const listItems = data.map((item, index) => (
    <li className={cnj("", cnLi && `${cnLi}`)} key={index}>
      {icon && <i>{icon}</i>}
      {item}
    </li>
  ));
  return <ul className={cnj("", clUl && `${clUl}`)}>{listItems}</ul>;
};

export const ListGroupItems = (props) => {
  const { data, cn } = props;

  const listItems = data.map((item, index) => (
    <li className={cnj("list-group-item", cn && `${cn}`)} key={index}>
      {item}
    </li>
  ));
  return <ul className="list-group">{listItems}</ul>;
};
