import React from "react";
import Footer from "../layout/Footer";
import AboutMe from "../sections/AboutMe/AboutMe";
import Brands from "../sections/Brands/Brands";
import Contact from "../sections/Contact/Contact";
import LetsContact from "../sections/LetsContact/LetsContact";
import MainHero from "../sections/MainHero2/MainHero";
import Services from "../sections/Services/Services";
import ServicesApp1C from "../sections/Services/ServicesApp1C";
import ServicesWeb from "../sections/Services/ServicesWeb";
import Skills from "../sections/Skills/Skills";

/**
 * Component for showing Home Page of the site.
 *
 * @component
 */
const HomePage = () => {
  return (
    <main className="portfolio-main" id="portfolio-main">
      <MainHero />
      <AboutMe />
      <Skills />
      <LetsContact path={"#section-contact"} />
      <Services />
      <ServicesApp1C />
      <ServicesWeb />
      <Brands />
      <Contact />
      <Footer />
    </main>
  );
};
/*<MainLayoutPage>

</MainLayoutPage>
<PageMeta metaData={dataHomePage} />

*/
export default HomePage;
