import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { RiCheckDoubleFill } from "react-icons/ri";
import { dataSectionServices } from "../../../data/sections";
import Image1 from "../../../images/services1.png";
import { ListItems } from "../../utils/List/ListItems";
import SiteSection from "../../utils/SiteSection/SiteSection";
import { Paragraph } from "../../utils/Utils";
import "./Services.scss";

const dataSectionContent = {
  image: Image1,
  alt: "Building amazing 1C, web & mobile apps | LovkovAV.PRO",
};

const ServicesWeb = () => {
  const { t } = useTranslation("nsServices");

  const HeadlineContent = () => {
    const { title, subtitle, description } = t("app1c.content", {
      returnObjects: true,
    });
    const features = t("app1c.features", { returnObjects: true });
    return (
      <div className="col-12 col-lg-7 mb-3 order-2 content">
        <div className="content-title service-detail-title">
          <h2>{title}</h2>
        </div>
        <div className="content-text service-detail-text">
          <Paragraph text={description} />
          <ListItems data={features} icon={<RiCheckDoubleFill />} />
        </div>
      </div>
    );
  };

  const HeadlineContentTranslation = withTranslation()(HeadlineContent);

  const SectionContent = (props) => {
    const { data } = props;
    return (
      <div className="service-detail">
        <div className="row">
          <HeadlineContentTranslation />
          <div className="col-12 col-lg-5 mb-3 order-2">
            <div className="service-detail-image image">
              <picture>
                <img src={data.image} alt={data.alt} />
              </picture>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <SiteSection name="services-web" data={dataSectionServices}>
      <SectionContent data={dataSectionContent} />
    </SiteSection>
  );
};

export default ServicesWeb;
