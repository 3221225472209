import React from "react";
import { Translation } from "react-i18next";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaSkype,
  FaTwitter,
} from "react-icons/fa";

export const dataSocial = [
  {
    title: "facebook",
    cn: "facebook",
    path: "https://www.facebook.com/lovkovav",
    icon: <FaFacebookF />,
  },
  {
    title: "linkedin",
    cn: "linkedin",
    path: "www.linkedin.com/in/lovkovav",
    icon: <FaLinkedinIn />,
  },
  {
    title: "skype",
    cn: "skype",
    path: "skype:aleks.lovkov?call",
    icon: <FaSkype />,
  },
  {
    title: "@twitter",
    cn: "twitter",
    path: "https://twitter.com/LovkovAlexander",
    icon: <FaTwitter />,
  },
  {
    title: "instagram",
    cn: "instagram",
    path: "https://www.instagram.com/ddampty/",
    icon: <FaInstagram />,
  },
];

export const dataMySkills = [
  {
    title: (
      <Translation ns={"nsSkill"}>{(t) => t("details.0.title")}</Translation>
    ),
    percent: 100,
  },
  {
    title: (
      <Translation ns={"nsSkill"}>{(t) => t("details.1.title")}</Translation>
    ),
    percent: 80,
  },
  { title: "Java (J2EE, Spring, REST APIs)", percent: 100 },
  { title: "DBs (MS SQL, PgSQL, MongoDB)", percent: 82 },
  { title: "Other (Git, Jira)", percent: 89 },
];
