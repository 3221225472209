import React, { cloneElement, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Panel, Tabs, useTabState } from "../../../hooks/useTabs";
import "./PoliciesModal.scss";

const cn = (...args) => args.filter(Boolean).join(" ");

const Tab = ({ style, children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button
      className={cn("btn", style, isActive && "active")}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const PanelList = ({ state, children }) => {
  const panelRef = useRef();
  const [, setHeight] = useState(0);
  const [activeIndex] = state;

  useEffect(() => {
    setHeight(panelRef.current.offsetHeight);
  }, [activeIndex, setHeight]);

  return (
    <div>
      <div ref={panelRef}>
        {cloneElement(children[activeIndex], { active: true })}
      </div>
    </div>
  );
};

const PoliciesModal = (props) => {
  const state = useState(0);
  const { show, closeModal } = props;

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!show) {
    return null;
  }

  const TermsAndConditionsPolicy = () => {
    return (
      <div className="policy">
        <h2>Terms and Conditions</h2>
        <ol className="policy-list">
          <li>
            Introduction
            <ol className="policy-sublist">
              <li>
                These terms and conditions govern your use of this website; by
                using this website, you accept these terms and conditions in
                full. If you disagree with these terms and conditions or any
                part of these terms and conditions, you must not use this
                website.
              </li>
              <li>
                You must be at least 18 years of age to use this website. By
                using this website and by agreeing to these terms and conditions
                you warrant and represent that you are at least 18 years of age.
              </li>
              <li>
                This website uses cookies. By using this website and agreeing to
                these terms and conditions, you consent to our
                <span>LovkovAV.PRO's</span> use of cookies in accordance with the
                terms of <span>LovkovAV.PRO's</span> cookies policy.
              </li>
            </ol>
          </li>
          <li>
            License to use website
            <ol className="policy-sublist">
              <li>
                Unless otherwise stated, <span>LovkovAV.PRO</span> and/or its
                licensors own the intellectual property rights in the website
                and material on the website. Subject to the license below, all
                these intellectual property rights are reserved.
              </li>
              <li>
                You may view, download for caching purposes only, and print
                pages from the website for your own personal use, subject to the
                restrictions set out below and elsewhere in these terms and
                conditions.
              </li>
              <li>
                This website uses cookies. By using this website and agreeing to
                these terms and conditions, you consent to our
                <span>LovkovAV.PRO's</span> use of cookies in accordance with the
                terms of <span>LovkovAV.PRO's</span> cookies policy.
              </li>
              <li>You must not:</li>
              <ul>
                <li>
                  republish material from this website (including republication
                  on another website) without active, dofollow link on this web
                  page of <span>LovkovAV.PRO's</span> website
                </li>
                <li>
                  sell, rent or sub-license material from the website without
                  active, dofollow link on this web page of{" "}
                  <span>LovkovAV.PRO's</span>
                  website
                </li>
                <li>
                  show any material from the website in public without active,
                  dofollow link on this web page of <span>LovkovAV.PRO's</span>{" "}
                  website
                </li>
                <li>
                  reproduce, duplicate, copy or otherwise exploit material on
                  this website for a commercial purpose without active, dofollow
                  link on this web page of <span>LovkovAV.PRO's</span> website
                </li>
              </ul>
              <li>
                Where content is specifically made available for redistribution,
                it may only be redistributed.
              </li>
            </ol>
          </li>
          <li>
            Acceptable use
            <ol className="policy-sublist">
              <li>
                You must not use this website in any way that causes, or may
                cause, damage to the website or impairment of the availability
                or accessibility of the website; or in any way which is
                unlawful, illegal, fraudulent or harmful, or in connection with
                any unlawful, illegal, fraudulent or harmful purpose or
                activity.
              </li>
              <li>
                You must not use this website to copy, store, host, transmit,
                send, use, publish or distribute any material which consists of
                (or is linked to) any spyware, computer virus, Trojan horse,
                worm, keystroke logger, rootkit or other malicious computer
                software.
              </li>
              <li>
                You must not conduct any systematic or automated data collection
                activities (including without limitation scraping, data mining,
                data extraction and data harvesting) on or in relation to this
                website without <span>LovkovAV.PRO's</span> express written consent.
              </li>
            </ol>
          </li>
          <li>
            Restricted access
            <ol className="policy-sublist">
              <li>
                Access to certain areas of this website is restricted.
                <span>LovkovAV.PRO</span>
                reserves the right to restrict access to other areas of this
                website, or indeed this entire website, at{" "}
                <span>LovkovAV.PRO's</span>
                discretion.
              </li>
              <li>
                If <span>LovkovAV.PRO</span> provides you with a user ID and password
                to enable you to access restricted areas of this website or
                other content or services, you must ensure that the user ID and
                password are kept confidential.
              </li>
            </ol>
          </li>
          <li>
            User content
            <ol className="policy-sublist">
              <li>
                In these terms and conditions, “your user content” means
                material (including without limitation text, images, audio
                material, video material and audio-visual material) that you
                submit to this website, for whatever purpose.
              </li>
              <li>
                You grant to <span>LovkovAV.PRO</span> a worldwide, irrevocable,
                non-exclusive, royalty-free license to use, reproduce, adapt,
                publish, translate and distribute your user content in any
                existing or future media. You also grant to <span>LovkovAV.PRO</span>{" "}
                the right to sub-license these rights, and the right to bring an
                action for infringement of these rights.
              </li>
              <li>
                Your user content must not be illegal or unlawful, must not
                infringe any third party's legal rights, and must not be capable
                of giving rise to legal action whether against you or{" "}
                <span>LovkovAV.PRO</span> or a third party(in each case under any
                applicable law).
              </li>
              <li>
                You must not submit any user content to the website that is or
                has ever been the subject of any threatened or actual legal
                proceedings or other similar complaint.
              </li>
              <li>
                <span>LovkovAV.PRO</span> reserves the right to edit or remove any
                material submitted to this website, or stored on{" "}
                <span>LovkovAV.PRO's</span> servers, or hosted or published upon this
                website.
              </li>
            </ol>
          </li>
          <li>
            No warranties
            <ol className="policy-sublist">
              <li>
                This website is provided “as is” without any representations or
                warranties, express or implied. <span>LovkovAV.PRO</span> makes no
                representations or warranties in relation to this website or the
                information and materials provided on this website.
              </li>
              <li>
                Without prejudice to the generality of the foregoing paragraph,
                <span>LovkovAV.PRO</span> does not warrant that:
                <ul>
                  <li>
                    his website will be constantly available, or available at
                    all; or
                  </li>

                  <li>
                    the information on this website is complete, true, accurate
                    or non-misleading.
                  </li>
                </ul>
              </li>
              <li>
                Nothing on this website constitutes, or is meant to constitute,
                advice of any kind.
              </li>
            </ol>
          </li>
          <li>
            Limitations of liability
            <ol className="policy-sublist">
              <li>
                <span>LovkovAV.PRO</span> will not be liable to you (whether under
                the law of contact, the law of torts or otherwise) in relation
                to the contents of, or use of, or otherwise in connection with,
                this website
                <ul>
                  <li>for any indirect, special or consequential loss; or</li>
                  <li>
                    for any business losses, loss of revenue, income, profits or
                    anticipated savings, loss of contracts or business
                    relationships, loss of reputation or goodwill, or loss or
                    corruption of information or data.
                  </li>
                </ul>
              </li>
              <li>
                These limitations of liability apply even if{" "}
                <span>LovkovAV.PRO</span>
                has been expressly advised of the potential loss.
              </li>
            </ol>
          </li>
          <li>
            Exceptions
            <ol className="policy-sublist">
              <li>
                Nothing in this website disclaimer will exclude or limit any
                warranty implied by law that it would be unlawful to exclude or
                limit; and nothing in this website disclaimer will exclude or
                limit <span>LovkovAV.PRO's</span> liability in respect of any:
              </li>
              <li>
                Without prejudice to the generality of the foregoing paragraph,
                <span>LovkovAV.PRO</span> does not warrant that:
                <ul>
                  <li>
                    death or personal injury caused by <span>LovkovAV.PRO's</span>
                    negligence
                  </li>
                  <li>
                    fraud or fraudulent misrepresentation on the part of
                    <span>LovkovAV.PRO</span>; or
                  </li>
                  <li>
                    matter which it would be illegal or unlawful for
                    <span>LovkovAV.PRO</span> to exclude or limit, or to attempt or
                    purport to exclude or limit, its liability
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            Reasonableness
            <ol className="policy-sublist">
              <li>
                By using this website, you agree that the exclusions and
                limitations of liability set out in this website disclaimer are
                reasonable.
              </li>
              <li>
                If you do not think they are reasonable, you must not use this
                website.
              </li>
            </ol>
          </li>
          <li>
            Unenforceable provisions
            <ol className="policy-sublist">
              <li>
                You hereby indemnify <span>LovkovAV.PRO</span> and undertake to keep{" "}
                <span>LovkovAV.PRO</span>
                indemnified against any losses, damages, costs, liabilities and
                expenses (including without limitation legal expenses and any
                amounts paid by LovkovAV.PRO to a third party in settlement of a
                claim or dispute on the advice of <span>LovkovAV.PRO's</span> legal
                advisers) incurred or suffered by LovkovAV.PRO arising out of any
                breach by you of any provision of these terms and conditions.
              </li>
            </ol>
          </li>
          <li>
            Indemnity
            <ol className="policy-sublist">
              <li>
                If any provision of this website disclaimer is, or is found to
                be, unenforceable under applicable law, that will not affect the
                enforceability of the other provisions of this website
                disclaimer.
              </li>
            </ol>
          </li>
          <li>
            Breaches of these terms and conditions
            <ol className="policy-sublist">
              <li>
                Without prejudice to <span>LovkovAV.PRO's</span> other rights under
                these terms and conditions, if you breach these terms and
                conditions in any way, <span>LovkovAV.PRO</span> may take such action
                as <span>LovkovAV.PRO</span> deems appropriate to deal with the
                breach, including suspending your access to the website,
                prohibiting you from accessing the website, blocking computers
                using your IP address from accessing the website, contacting
                your internet service provider to request that they block your
                access to the website and/ or bringing court proceedings against
                you.
              </li>
            </ol>
          </li>
          <li>
            Variation
            <ol className="policy-sublist">
              <li>
                <span>LovkovAV.PRO</span> may revise these terms and conditions from
                time-to-time. Revised terms and conditions will apply to the use
                of this website from the date of the publication of the revised
                terms and conditions on this website. Please check this page
                regularly to ensure you are familiar with the current version.
              </li>
            </ol>
          </li>
          <li>
            Assignment
            <ol className="policy-sublist">
              <li>
                <span>LovkovAV.PRO</span> may transfer, sub-contract or otherwise
                deal with
                <span>LovkovAV.PRO's</span> rights and / or obligations under these
                terms and conditions without notifying you or obtaining your
                consent.
              </li>
              <li>
                You may not transfer, sub-contract or otherwise deal with your
                rights and/or obligations under these terms and conditions.
              </li>
            </ol>
          </li>
          <li>
            Severability
            <ol>
              <li>
                If a provision of these terms and conditions is determined by
                any court or other competent authority to be unlawful and/or
                unenforceable, the other provisions will continue in effect.
              </li>
              <li>
                If any unlawful and/or unenforceable provision would be lawful
                or enforceable if part of it were deleted, that part will be
                deemed to be deleted, and the rest of the provision will
                continue in effect.
              </li>
            </ol>
          </li>
          <li>
            Entire agreement
            <ol className="policy-sublist">
              <li>
                These terms and conditions constitute the entire agreement
                between you and <span>LovkovAV.PRO</span> in relation to your use of
                this website, and supersede all previous agreements in respect
                of your use of this website
              </li>
            </ol>
          </li>
          <li>
            Law and jurisdiction
            <ol className="policy-sublist">
              <li>
                These terms and conditions will be governed by and construed in
                accordance with the laws of USA, and any disputes relating to
                these terms and conditions will be subject to the exclusive
                jurisdiction of the courts of USA.
              </li>
            </ol>
          </li>
        </ol>
        <strong>LovkovAV.PRO's details:</strong>
        <br />
        The full name of LovkovAV.PRO is Alexander Lovkov.
        <br />
        You can contact LovkovAV.PRO by email to lovkovav@gmail.com.
      </div>
    );
  };

  const PrivacyPolicy = () => {
    return (
      <div className="policy">
        <h2>Privacy policy</h2>
        <ol className="policy-list">
          <li>
            Introduction
            <ol className="policy-sublist">
              <li>Our website uses cookies.</li>
              <li>
                We are committed to safeguarding the privacy of our website
                visitors; in this policy we explain how we will treat your
                personal information.
              </li>
              <li>
                We will ask you to consent to our use of cookies in accordance
                with the terms of this policy when you first visit our website.
              </li>
            </ol>
          </li>

          <li>
            Collecting personal information
            <ol className="policy-sublist">
              <li>
                We may collect, store and use the following kinds of personal
                information:
                <ul>
                  <li>
                    information about your computer and about your visits to and
                    use of this website (including your IP address, geographical
                    location, browser type and version, operating system,
                    referral source, length of visit, page views and website
                    navigation paths);
                  </li>
                  <li>
                    information that you provide when completing your profile on
                    our website (including your name);
                  </li>
                  <li>
                    information that you provide to us for the purpose of
                    subscribing to our email notifications and/or newsletters
                    (including your name and email address);
                  </li>
                </ul>
              </li>
              <li>
                Before you disclose to us the personal information of another
                person, you must obtain that person's consent to both the
                disclosure and the processing of that personal information in
                accordance with this policy..
              </li>
            </ol>
          </li>

          <li>
            Using personal information
            <ol className="policy-sublist">
              <li>
                Personal information submitted to us through our website will be
                used for the purposes specified in this policy or on the
                relevant pages of the website.
              </li>
              <li>
                We may use your personal information to:
                <ul>
                  <li>administer our website and business;</li>
                  <li>personalise our website for you;</li>
                  <li>
                    send statements, invoices and payment reminders to you, and
                    collect payments from you;
                  </li>
                  <li>send you non-marketing commercial communications;</li>
                  <li>
                    send you email notifications that you have specifically
                    requested;
                  </li>
                  <li>
                    send you our email newsletter, if you have requested it (you
                    can inform us at any time if you no longer require the
                    newsletter);
                  </li>
                  <li>
                    send you marketing communications relating to our business
                    or the businesses of carefully-selected third parties which
                    we think may be of interest to you, by post or, where you
                    have specifically agreed to this, by email or similar
                    technology (you can inform us at any time if you no longer
                    require marketing communications);
                  </li>
                </ul>
              </li>
              <li>
                If you submit personal information for publication on our
                website, we will publish and otherwise use that information in
                accordance with the licence you grant to us.
              </li>
              <li>
                Your privacy settings can be used to limit the publication of
                your information on our website, and can be adjusted using
                privacy controls on the website.
              </li>
              <li>
                We will not, without your express consent, supply your personal
                information to any third party for the purpose of their or any
                other third party's direct marketing.
              </li>
            </ol>
          </li>

          <li>
            Disclosing personal information
            <ol className="policy-sublist">
              <li>
                We may disclose your personal information to any of our
                employees insofar as reasonably necessary for the purposes set
                out in this policy.
              </li>
              <li>
                We may disclose your personal information to any member of our
                group of companies (this means our subsidiaries, our ultimate
                holding company and all its subsidiaries) insofar as reasonably
                necessary for the purposes set out in this policy.
              </li>
              <li>
                We may disclose your personal information:
                <ul>
                  <li>to the extent that we are required to do so by law;</li>
                  <li>
                    in connection with any ongoing or prospective legal
                    proceedings;
                  </li>
                  <li>
                    in order to establish, exercise or defend our legal rights
                    (including providing information to others for the purposes
                    of fraud prevention and reducing credit risk);
                  </li>
                  <li>
                    to any person who we reasonably believe may apply to a court
                    or other competent authority for disclosure of that personal
                    information where, in our reasonable opinion, such court or
                    authority would be reasonably likely to order disclosure of
                    that personal information.
                  </li>
                </ul>
              </li>
              <li>
                Except as provided in this policy, we will not provide your
                personal information to third parties.
              </li>
            </ol>
          </li>
        </ol>
        <strong>LovkovAV.PRO's details:</strong>
        <br />
        The full name of LovkovAV.PRO is Alexander Lovkov.
        <br />
        You can contact LovkovAV.PRO by email to lovkovav@gmail.com.
      </div>
    );
  };

  const CookiesPolicy = () => {
    return (
      <div className="policy">
        <h2>Cookies policy</h2>
        <ol className="policy-list">
          <li>
            Introduction
            <ol className="policy-sublist">
              <li>Our website uses cookies.</li>
              <li>
                We will ask you to consent to our use of cookies in accordance
                with the terms of this policy when you first visit our website.
              </li>
            </ol>
          </li>
          <li>
            About cookies
            <ol className="policy-sublist">
              <li>
                A cookie is a file containing an identifier (a string of letters
                and numbers) that is sent by a web server to a web browser and
                is stored by the browser. The identifier is then sent back to
                the server each time the browser requests a page from the
                server.
              </li>
              <li>
                Cookies may be either "persistent" cookies or "session" cookies:
                a persistent cookie will be stored by a web browser and will
                remain valid until its set expiry date, unless deleted by the
                user before the expiry date; a session cookie, on the other
                hand, will expire at the end of the user session, when the web
                browser is closed.
              </li>
              <li>
                Cookies do not typically contain any information that personally
                identifies a user, but personal information that we store about
                you may be linked to the information stored in and obtained from
                cookies
              </li>
              <li>
                Cookies can be used by web servers to identify and track users
                as they navigate different pages on a website and identify users
                returning to a website.
              </li>
            </ol>
          </li>
          <li>
            Our cookies
            <ol className="policy-sublist">
              <li>
                We use both session and persistent cookies on our website.
              </li>
              <li>
                The names of the cookies that we use on our website, and the
                purposes for which they are used, are set out below: we use
                jv_client_id to recognise a computer when a user visits our
                website, track users as they navigate the website, improve the
                website's usability.
              </li>
            </ol>
          </li>
          <li>
            Analytics cookies
            <ol className="policy-sublist">
              <li>
                We use Google Analytics to analyse the use of our website.
              </li>
              <li>
                Our analytics service provider generates statistical and other
                information about website use by means of cookies.
              </li>
              <li>
                The analytics cookies used by our website have the following
                names: _ga
              </li>
              <li>
                The information generated relating to our website is used to
                create reports about the use of our website.
              </li>
              <li>
                Our analytics service provider's privacy policy is available at:
                http://www.google.com/policies/privacy/
              </li>
            </ol>
          </li>
          <li>
            Blocking cookies
            <ol className="policy-sublist">
              <li>
                Most browsers allow you to refuse to accept cookies; for
                example:
                <ul>
                  <li>
                    in Internet Explorer (version 11) you can block cookies
                    using the cookie handling override settings available by
                    clicking "Tools", "Internet Options", "Privacy" and then
                    "Advanced";
                  </li>
                  <li>
                    in Firefox (version 44) you can block all cookies by
                    clicking "Tools", "Options", "Privacy", selecting "Use
                    custom settings for history" from the drop-down menu, and
                    unticking "Accept cookies from sites"; and
                  </li>
                  <li>
                    in Chrome (version 48), you can block all cookies by
                    accessing the "Customise and control" menu, and clicking
                    "Settings", "Show advanced settings" and "Content settings",
                    and then selecting "Block sites from setting any data" under
                    the "Cookies" heading
                  </li>
                </ul>
              </li>
              <li>
                We will ask you to consent to our use of cookies in accordance
                with the terms of this policy when you first visit our website.
              </li>
              <li>
                If you block cookies, you will not be able to use all the
                features on our website.
              </li>
            </ol>
          </li>
          <li>
            Deleting cookies
            <ol className="policy-sublist">
              <li>
                You can delete cookies already stored on your computer; for
                example:
                <ul>
                  <li>
                    in Internet Explorer (version 11), you must manually delete
                    cookie files (you can find instructions for doing so at
                    http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11);
                  </li>
                  <li>
                    in Firefox (version 44), you can delete cookies by clicking
                    "Tools", "Options" and "Privacy", then selecting "Use custom
                    settings for history" from the drop-down menu, clicking
                    "Show Cookies", and then clicking "Remove All Cookies"; and
                  </li>
                  <li>
                    in Chrome (version 48), you can delete all cookies by
                    accessing the "Customise and control" menu, and clicking
                    "Settings", "Show advanced settings" and "Clear browsing
                    data", and then selecting "Cookies and other site and
                    plug-in data" before clicking "Clear browsing data".
                  </li>
                </ul>
              </li>
              <li>
                Deleting cookies will have a negative impact on the usability of
                many websites.
              </li>
            </ol>
          </li>

          <li>
            International data transfers
            <ol className="policy-sublist">
              <li>
                We are committed to safeguarding the privacy of our website
                visitors; in this policy we explain how we will treat your
                personal information.
              </li>
              <li>
                We will ask you to consent to our use of cookies in accordance
                with the terms of this policy when you first visit our website.
              </li>
              <li>
                Personal information that you publish on our website or submit
                for publication on our website may be available, via the
                internet, around the world. We cannot prevent the use or misuse
                of such information by others.
              </li>
              <li>
                You expressly agree to the transfers of personal information
                described in this Section 5.
              </li>
            </ol>
          </li>

          <li>
            Retaining personal information
            <ol className="policy-sublist">
              <li>
                This Section 6 sets out our data retention policies and
                procedure, which are designed to help ensure that we comply with
                our legal obligations in relation to the retention and deletion
                of personal information.
              </li>
              <li>
                Personal information that we process for any purpose or purposes
                shall not be kept for longer than is necessary for that purpose
                or those purposes.
                <ul>
                  <li>to the extent that we are required to do so by law;</li>
                  <li>
                    if we believe that the documents may be relevant to any
                    ongoing or prospective legal proceedings;
                  </li>
                  <li>
                    in order to establish, exercise or defend our legal rights
                    (including providing information to others for the purposes
                    of fraud prevention and reducing credit risk).
                  </li>
                </ul>
              </li>
            </ol>
          </li>

          <li>
            Security of personal information
            <ol className="policy-sublist">
              <li>
                We will take reasonable technical and organisational precautions
                to prevent the loss, misuse or alteration of your personal
                information.
              </li>
              <li>
                We will store all the personal information you provide on our
                secure (password- and firewall-protected) servers.
              </li>
            </ol>
          </li>

          <li>
            Your rights
            <ol className="policy-sublist">
              <li>
                You may instruct us to provide you with any personal information
                we hold about you; provision of such information will be subject
                to:
                <ul>
                  <li>
                    {" "}
                    the supply of appropriate evidence of your identity (for
                    this purpose, we will usually accept a photocopy of your
                    passport certified by a solicitor).
                  </li>
                </ul>
              </li>
              <li>
                We may withhold personal information that you request to the
                extent permitted by law.
              </li>
              <li>
                You may instruct us at any time not to process your personal
                information for marketing purposes.
              </li>
              <li>
                In practice, you will usually either expressly agree in advance
                to our use of your personal information for marketing purposes,
                or we will provide you with an opportunity to opt out of the use
                of your personal information for marketing purposes.
              </li>
            </ol>
          </li>

          <li>
            Amendments
            <ol className="policy-sublist">
              <li>
                We may update this policy from time to time by publishing a new
                version on our website.
              </li>
              <li>
                You should check this page occasionally to ensure you are happy
                with any changes to this policy.
              </li>
              <li>We may notify you of changes to this policy by email.</li>
            </ol>
          </li>

          <li>
            Third party websites
            <ol className="policy-sublist">
              <li>
                Our website includes hyperlinks to, and details of, third party
                websites.
              </li>
              <li>
                We have no control over, and are not responsible for, the
                privacy policies and practices of third parties.
              </li>
            </ol>
          </li>

          <li>
            Updating information
            <ol className="policy-sublist">
              <li>
                Please let us know if the personal information that we hold
                about you needs to be corrected or updated.
              </li>
            </ol>
          </li>
        </ol>
        <strong>LovkovAV.PRO's details:</strong>
        <br />
        The full name of LovkovAV.PRO is Alexander Lovkov.
        <br />
        You can contact LovkovAV.PRO by email to lovkovav@gmail.com.
      </div>
    );
  };

  const PolicyTabs = () => {
    return (
      <Tabs state={state}>
        <ul className="tabs tab-nav">
          <li>
            <Tab>Terms and Conditions</Tab>
          </li>
          <li>
            <Tab>Privacy policy</Tab>
          </li>
          <li>
            <Tab>Cookies policy</Tab>
          </li>
        </ul>
        <div className="tab-progress" />
        <PanelList state={state}>
          <Panel>
            <TermsAndConditionsPolicy />
          </Panel>
          <Panel>
            <PrivacyPolicy />
          </Panel>
          <Panel>
            <CookiesPolicy />
          </Panel>
        </PanelList>
      </Tabs>
    );
  };

  const CloseButton = () => {
    return (
      <div className="modal-close-btn">
        <div className="modal-close-btn-inner">
          <button
            className="btn"
            onClick={closeModal}
            aria-label="Close the modal by clicking here"
          ></button>
        </div>
      </div>
    );
  };

  return (
    <div className="modal-wrapper our-policies">
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content-wrapper">
          <CloseButton />
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header content">
              <div className="logo">
                <Link to="/" className="navbar-brand" aria-label="Logo"></Link>
              </div>
              <h2 className="content-title modal-title">Our Policies</h2>
            </div>
            <div className="modal-body">
              <PolicyTabs />
            </div>
            <div className="modal-footer">
              <button className="btn btn-close" onClick={props.onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoliciesModal;
