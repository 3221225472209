import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { cnj } from "../Utils";

export const ReadMore = (props) => {
  const { text, path, cn, handleClick } = props;
  const linkInsteadOfButton = true;

  return (
    <span>
      {linkInsteadOfButton ? (
        <Link className={cnj("btn", cn && `${cn}`)} to={path}>
          {text}
        </Link>
      ) : (
        <button
          className={cnj("btn", cn && `${cn}`)}
          type="button"
          aria-label={text}
          onClick={handleClick}
        >
          {text}
          <i className="icon">
            <FaLongArrowAltRight />
          </i>
        </button>
      )}
    </span>
  );
};
