import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ContactForm.scss";

const ContactForm = (props) => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(false);

  const { t } = useTranslation();

  const dataContactForm = {
    labels: {
      fullname: t("sections.contact.form.fullname"),
      email: t("sections.contact.form.email"),
      message: t("sections.contact.form.message"),
      policy: t("sections.contact.form.policy"),
    },
    errors: {
      fullname: "Full name is required",
      email: "Contact e-mail is required",
      phone: "Contact phone number is required",
      message: "Project description is required",
      policy: "",
    },
  }; // const validateField = props.validateField;

  const handleChange = (e) => {
    const field = e.target.id;
    console.log(field);
    if (field === "fullname") {
      setFullname(e.target.value);
    } else if (field === "email") {
      setEmail(e.target.value);
    } else if (field === "message") {
      setMessage(e.target.value);
    }
    validateForm();
  };

  const validateForm = () => {
    const formValid =
      fullname.length > 0 && email.length > 0 && message.length > 0;
    console.log("Is valid " + formValid);

    setIsValid(formValid);
  };

  return (
    <form
      className="form-contact"
      onSubmit={props.onSubmit}
      id="form-contact"
      data-toggle="validator"
    >
      <div className="row no-gutters input-container">
        <input
          type="text"
          className="form-control input"
          placeholder={dataContactForm.labels.fullname}
          id="fullname"
          value={fullname}
          onChange={handleChange}
          required
          pattern="([a-zA-Z]+\s){0,}([a-zA-Z]+)"
          data-error={dataContactForm.errors.fullname}
        />

        <input
          type="email"
          className="form-control input"
          placeholder={dataContactForm.labels.email}
          id="email"
          value={email}
          onChange={handleChange}
          required
          size="64"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          data-error={dataContactForm.errors.email}
        />

        <textarea
          type="text"
          className="form-control input"
          placeholder={dataContactForm.labels.message}
          id="message"
          value={message}
          onChange={handleChange}
          rows="5"
          required
          size="2048"
          data-error={dataContactForm.errors.message}
        />
      </div>

      <div className="row submit-container">
        <div className="col-12 col-xl-8">
          <div className="form-group-checkbox">
            <p>
              {dataContactForm.labels.policy}
              <button
                className="btn btn-link"
                aria-label="Conditions"
                onClick={props.onShowPolicy}
              >
                {t("sections.contact.form.buttons.privacypolicy.title")}
              </button>
            </p>
            <input
              className="checkbox"
              id="terms"
              type="checkbox"
              name="terms"
            />
          </div>
        </div>
        <div className="col-12 col-xl-4">
          <div className="form-group-submit">
            <button
              className="btn btn-secondary btn-send-message"
              type="submit"
              id="form-submit"
              disabled={!isValid}
            >
              {t("sections.contact.form.buttons.sendmessage.title")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

//ContactForm.propTypes = {
//  config: PropTypes.object.isRequired,
//};

export default ContactForm;
