export default {
  BLOG: {
    PREVIEW_DESCRIPTION_LENGHT_LIMIT: 128,
    PREVIEW_METADATA: {
      CREATEDATE_FORMAT: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
    },
    GA_TRACKING_CODE: process.env.REACT_APP_GA_TRACKING_CODE,
  },
};
