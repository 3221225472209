import React from "react";
import { useLocation } from "react-router-dom";
import analytics from "../components/SEO/Analytics";

export default function useGoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    analytics.initGA();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    analytics.logPageView(currentPath);
  }, [location]);
}
