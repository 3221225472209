import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { dataSectionServices } from "../../../data/sections";
import SiteSection from "../../utils/SiteSection/SiteSection";
import "./Services.scss";

const Services = () => {
  const { t } = useTranslation("nsServices");
  const dataCards = t("cards", { returnObjects: true });

  const Card = (props) => {
    const { data } = props;

    return (
      <div
        className="addo-feature animate-box fadeInLeft animated"
        data-animate-effect="fadeInLeft"
      >
        <div className="addo-icon">
          <span className="icon">{data.icon}</span>
        </div>
        <div className="addo-text">
          <h3>{data.title}</h3>
          <p>{data.description}</p>
        </div>
      </div>
    );
  };

  const CardTranslation = withTranslation()(Card);

  const SectionContent = (props) => {
    return (
      <div className="row">
        {dataCards.map((item, index) => {
          return (
            <div className="col-lg-6 col-xl-4 content" key={index}>
              <CardTranslation data={item} key={index} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <SiteSection name="services" data={dataSectionServices}>
      <SectionContent />
    </SiteSection>
  );
};

export default Services;
