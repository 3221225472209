import React from "react";
import { Translation } from "react-i18next";
import * as BiIcons from "react-icons/bi";
import ImageAboutMe from "../images/about.jpg";

export const dataSectionMainHero = {
  title: <Translation>{(t) => t("sections.mainhero.title")}</Translation>,
  subtitle: <Translation>{(t) => t("sections.mainhero.subtitle")}</Translation>,
  description: (
    <Translation>{(t) => t("sections.mainhero.description")}</Translation>
  ),
};

export const dataSectionAboutMe = {
  title: <Translation ns={"nsAbout"}>{(t) => t("title")}</Translation>,
  subtitle: <Translation ns={"nsAbout"}>{(t) => t("subtitle")}</Translation>,
  description: "",
  cols: [{ col1: "1" }, { col2: "2" }],
  image: ImageAboutMe,
  alt: "",
};

export const dataSectionSkills = {
  title: <Translation ns={"nsSkill"}>{(t) => t("title")}</Translation>,
  subtitle: <Translation ns={"nsSkill"}>{(t) => t("subtitle")}</Translation>,
  description:
    "Suspendisse sit amet metus porta, egestas magna eget, ultricies enim. In libero justoner rhoncus eu nisl sed, pharetra luctus lorem. Praesent faucibus nibh nula necfermentum nunc rhoncus proin maximuster.\n" +
    "Quisque et nulla justo. Class aptent taciti sociosqu ad litora torquent per cobia nostra, per inceptos himenaeos. Mauris pulvinar faucibus dolory. Aenean dolry andapibus id lacinia a, posuere nec libero. Class aptent taciti sociosqu ad litora torquent per cobia nostra, per inceptos himenaeos.",
};

export const dataSectionLetsContact = {
  title: "",
  subtitle: "",
  description: (
    <Translation>{(t) => t("sections.letscontact.description")}</Translation>
  ),
  button: <Translation>{(t) => t("sections.letscontact.button")}</Translation>,
};

export const dataSectionContact = {
  title: <Translation>{(t) => t("sections.contact.title")}</Translation>,
  subtitle: <Translation>{(t) => t("sections.contact.subtitle")}</Translation>,
  description: (
    <Translation>{(t) => t("sections.contact.description")}</Translation>
  ),
  button: <Translation>{(t) => t("sections.contact.button")}</Translation>,
};

export const dataSectionServices = {
  title: <Translation ns={"nsServices"}>{(t) => t("title")}</Translation>,
  subtitle: <Translation ns={"nsServices"}>{(t) => t("subtitle")}</Translation>,
  description: (
    <Translation ns={"nsServices"}>{(t) => t("description")}</Translation>
  ),
};

export const dataSectionBrands = {
  title: <Translation>{(t) => t("sections.brands.title")}</Translation>,
  subtitle: <Translation>{(t) => t("sections.brands.subtitle")}</Translation>,
  description: "",
  image: null,
  alt: "",
};

export const dataSectionServicesCards = [
  {
    title: (
      <Translation ns={"nsServices"}>{(t) => t("cards.0.title")}</Translation>
    ),
    icon: <BiIcons.BiLayer />,
  },
  {
    title: (
      <Translation ns={"nsServices"}>{(t) => t("cards.1.title")}</Translation>
    ),
    icon: <BiIcons.BiLayer />,
  },
  {
    title: (
      <Translation ns={"nsServices"}>{(t) => t("cards.2.title")}</Translation>
    ),
    icon: <BiIcons.BiLayer />,
  },
  {
    title: (
      <Translation ns={"nsServices"}>{(t) => t("cards.3.title")}</Translation>
    ),
    icon: <BiIcons.BiLayer />,
  },
  {
    title: (
      <Translation ns={"nsServices"}>{(t) => t("cards.4.title")}</Translation>
    ),
    icon: <BiIcons.BiLayer />,
  },
  {
    title: (
      <Translation ns={"nsServices"}>{(t) => t("cards.5.title")}</Translation>
    ),
    icon: <BiIcons.BiLayer />,
  },
];

export const dataSectionExperience = {
  title: <Translation ns={"nsExperience"}>{(t) => t("title")}</Translation>,
  subtitle: (
    <Translation ns={"nsExperience"}>{(t) => t("subtitle")}</Translation>
  ),
  description: "",
};

export const dataSectionBlogList = {
  title: <Translation>{(t) => t("sections.blogs.title")}</Translation>,
  subtitle: <Translation>{(t) => t("sections.blogs.subtitle")}</Translation>,
  description: "",
};
