import React from "react";
import { NavLink as PageLink, useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
import { cnj } from "../Utils";

export const Button = (props) => {
  const { text, path, cn, handleClick } = props;
  const linkInsteadOfButton = true;
  const history = useHistory();
  //let location = useLocation();

  const handleSetActivePage = (e) => {
    e.preventDefault();
    const name = e.target.name;
    console.log(name);

    if (path.indexOf("/") !== -1) {
      //var routePath = path.replace("/", "");
      history.push("/");
    } else {
      scroller.scrollTo(name, { smooth: true });
    }
  };

  /*


  let location = useLocation();

  const [pathname, setPathname] = useState("");
  const [elementName, setElementName] = useState("");

  useEffect(() => {
    if (location.pathname !== pathname) setPathname(location.pathname);
    if (location.pathname === "/" && elementName) {
      scroller.scrollTo(elementName, { smooth: true });
      setElementName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
<div>
        {location.pathname === "/" ? (
          <NavLink to="section-main-hero" activeClass="active">
            {text}
          </NavLink>
        ) : (  )}
      </div>
  */
  const LinkToPage = () => {
    return (
      <PageLink
        className={cnj("btn", cn && `${cn}`)}
        to={path}
        name="section-contact"
        onClick={handleSetActivePage}
      >
        {text}
      </PageLink>
    );
  };

  return (
    <span>
      {linkInsteadOfButton ? (
        <LinkToPage />
      ) : (
        <button
          className={cnj("btn", cn && `${cn}`)}
          type="button"
          aria-label={text}
          onClick={handleClick}
        >
          {text}
        </button>
      )}
    </span>
  );
};
