import React from "react";
import { useTranslation } from "react-i18next";
import Typewriter from "typewriter-effect";
import { dataSectionAboutMe } from "../../../data/sections";
import { DownloadCV as ButtonDownloadCV } from "../../utils/Buttons/DownloadCV";
import { ReadMore as ButtonReadMore } from "../../utils/Buttons/ReadMore";
import SiteSection from "../../utils/SiteSection/SiteSection";
import { Paragraph } from "../../utils/Utils";
import ContactDetail from "../AboutMe/ContactDetal";
import "./AboutMe.scss";

const AboutMe = (props) => {
  const { t } = useTranslation("nsAbout");
  const details = t("details", { returnObjects: true });

  const TypewriterTitle = () => {
    const { t } = useTranslation();
    const text1 = t("sections.mainhero.details.0");
    const text2 = t("sections.mainhero.details.1");
    const text3 = t("sections.mainhero.details.2");

    const textDescription = t("sections.mainhero.descriptionAbout");

    return (
      <h2 className="section-subtitle">
        <span>{textDescription} &amp; </span>
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
            wrapperClassName: "typewriter-wrapper",
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(text1)
              .pauseFor(2000)
              .deleteAll()
              .typeString(text2)
              .pauseFor(2000)
              .deleteAll()
              .typeString(text3)
              .pauseFor(2000)
              .deleteAll()
              .start();
          }}
        />
      </h2>
    );
  };

  return (
    <SiteSection name="about" data={dataSectionAboutMe}>
      <div className="row">
        <div className="col-lg-5 mb-3" data-animate-effect="fadeInRight">
          <img
            src={dataSectionAboutMe.image}
            className="img-fluid"
            data-animate-effect="fadeInLeft"
            alt={dataSectionAboutMe.alt}
          />
        </div>
        <div className="col-lg-7 mb-3" data-animate-effect="fadeInLeft">
          <TypewriterTitle />
          <Paragraph text={t("description")} />
          <div className="group-contact-details">
            <div className="row">
              {details.map((item, index) => {
                return (
                  <div className="col-6" key={index}>
                    {item.col.map((itemDetail, indexDetail) => {
                      return (
                        <ContactDetail key={indexDetail} data={itemDetail} />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="group-buttons">
            <ButtonReadMore
              cn="btn-primary"
              text={t("buttons.readmore.title")}
              path="about"
            />
            <ButtonDownloadCV
              cn="btn-primary"
              text={t("buttons.CV.title")}
              path={t("buttons.CV.path")}
            />
          </div>
        </div>
      </div>
    </SiteSection>
  );
};

export default AboutMe;
