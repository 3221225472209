import React from "react";
import { cnj } from "../Utils";

export const SocialButtons = (props) => {
  const { data, cn } = props;

  return (
    <ul className="list-inline social-network">
      {data.map((item, index) => {
        return (
          <li
            className="list-inline-item"
            key={index}
            data-aos={(index + 1) % 2 ? "fade-up" : "fade-down"}
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-delay={5 * index}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={item.path}
              className={cnj("", cn && cn)}
            >
              {item.icon}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
